<template>
  <div>
    <s-crud
      title="Productores SAP"
      :config="configCrud"
      @save="save($event)"
      :edit="this.$fun.getSecurity().IsLevelAdmin"
      :add="this.$fun.getSecurity().IsLevelAdmin"
      :remove="this.$fun.getSecurity().IsLevelAdmin"
      sortable
      @rowSelected="rowSelected($event)"
      @edit="edit($event)"
      :filter="filter"
      :addNoFunction="addNoFunction"
      search-input
      ref="crud"
    >
      <template v-slot:PrdStatusSap="{ row }">
        <v-chip
          style="margin:0px"
          x-small
          :color="row.PrdStatusSap == 1 ? 'success' : 'error'"
        >
          {{ row.PrdStatusSap == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>
    </s-crud>
  </div>
</template>

<script>
import _sProducerService from "@/services/Technicalassistance/ProducerService";
import sToolbarPerson from "@/components/TecnicalAssistence/ProducersFarms/sToolbarPerson";
import _Service from "@/services/Technicalassistance/PersonService";

export default {
  name: "RequirementServiceProducer",
  components: { sToolbarPerson },
  props: {
    parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      filter: { IsPrimary: 0, FagID: 0 },

      dialogEdit: false,
      producer: {
        //VALORES DE LAS DEFINICIONES
        TypeProducer: 0,
        TypeSupplier: 0,
        PrdStatusSap: 1,
      },
    };
  },

  computed: {
    configCrud() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA

          PrdID: "ID",
          /* PrsID:"int", */
          PrdCardName: "string",
          PrdStatusSap: "int",
        },

        service: _sProducerService,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "PrdID", sortable: false },
              { text: "Nombre del Productor", value: "PrdCardName", },
              { text: "Estado", value: "PrdStatusSap", sortable: false },
            ]
          : [
              // CABECERA EN CASO NO SE LOGEE COMO ADMIN
              { text: "ID", value: "PrdID", sortable: false },
              {
                text: "Nombre del Productor",
                value: "PrdCardName",
                sortable: false,
              },
              { text: "Estado", value: "PrdStatusSap", sortable: false },
            ],
      };
    },

    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
      console.log(this.$fun.getSecurity().IsLevelAdmin);
    },
  },

  methods: {
    Initialize() {
      this.filter = { IsPrimary: 0, FagID: this.parentID };
      console.log(this.filter);
    },

    rowSelected(rows) {
      // metodo
      if (rows.length > 0) {
        console.log(rows[0]);
        this.$emit("rowSelected", rows); // emite un evento al padre digitador 3
      }
    },
  },
  watch: {
    parentID() {
      this.Initialize();
    },
  },

  created() {
    this.filter = { IsPrimary: 0, FagID: this.parentID };
  },
};
</script>
