<template>
  <div>
    <!-- <v-row lg="12">
      <v-col>
          <v-row justify="center">
            <v-col lg="3">
              <v-btn 
                depressed
                color="primary" 
                block
              >
                Sincronizar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
    </v-row> -->

    <v-row lg="12">
      <v-col lg="12">
        <producer-sun-expert>
        </producer-sun-expert>
      </v-col>
      <!-- <v-col lg="6">
        <producer-sap>
        </producer-sap>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import ProducerSap from './SapLoad/ProducerSap.vue';
import ProducerSunExpert from './SapLoad/ProducerSunExpert.vue';

  export default {
  components: { ProducerSap, ProducerSunExpert },
    
  }
</script>

<style scoped>

</style>