<template>
  <div>
    <s-crud
        load
        @load="loadSAP()"
        title="Productores SunExpert"
        :config="configCrud"
        sortable
        @rowSelected="rowSelected($event)"
        :filter="filter"
        :addNoFunction="addNoFunction"
        search-input
        ref="crud"
    >
      <template v-slot:PrdStatusSap="{ row }">
        <v-chip
          style="margin:0px"
          x-small
          :color="row.PrdStatusSap == 1 ? 'success' : 'error'"
        >
          {{ row.PrdStatusSap == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>
    </s-crud>

    <v-dialog v-model="processingSAP" :persistent="messageProcessingSAP.length == 0" width="400">
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessingSAP.length == 0">
            <div class="pt-2">
              Por favor espere
            </div>
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar operacion en SAP <br />
              {{ messageProcessingSAP }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import _sProducerService from "@/services/Technicalassistance/ProducerService";
import sToolbarPerson from "@/components/TecnicalAssistence/ProducersFarms/sToolbarPerson";
import _Service from "@/services/Technicalassistance/PersonService";

export default {
  name: "RequirementServiceProducer",
  components: { sToolbarPerson },
  props: {
    parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      TasProducer : [],
      processingSAP: false,
      messageProcessingSAP: "",

      filter: { IsPrimary: 0, FagID: 0, PrdStatusSap: 0 },

      dialogEdit: false,
      producer: {
        //VALORES DE LAS DEFINICIONES
        TypeProducer: 0,
        TypeSupplier: 0,
        PrdStatusSap: 1,
      },
    };
  },

  computed: {
    configCrud() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA

          PrdID: "ID",
          /* PrsID:"int", */
          PrdCardName: "string",
          PrdStatusSap: "int",
        },

        service: _sProducerService,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "PrdID", sortable: false, showSelect: true },
              {
                text: "Nombre del Productor",
                value: "PrdCardName",
                sortable: false,
              },
              { text: "Estado", value: "PrdStatusSap", sortable: false },
            ]
          : [
              // CABECERA EN CASO NO SE LOGEE COMO ADMIN
              { text: "ID", value: "PrdID", sortable: false },
              {
                text: "Nombre del Productor",
                value: "PrdCardName",
                sortable: false,
              },
              { text: "Estado", value: "PrdStatusSap", sortable: false },
            ],
      };
    },

    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
    },
  },

  methods: {
    Initialize() {
      this.filter = { IsPrimary: 0, FagID: this.parentID,  PrdStatusSap: 0 };
    },

    rowSelected(rows) {
      // metodo
      if (rows.length > 0) {
        this.TasProducer = rows[0];
        this.$emit("rowSelected", rows); // emite un evento al padre digitador 3
      }
    },

    loadSAP() {
      this.processingSAP = true;
      this.messageProcessingSAP = "";
      _sProducerService.loadsap(this.TasProducer, this.$fun.getUserID()).then(
        (r) => {
          this.$fun.alert("Productores cargados correctamente", "success");
          this.$refs.crud.refresh();
          this.processingSAP = false;
          this.messageProcessingSAP = "";
        },
        (e) => {
          this.messageProcessingSAP = e.response.data.Message;
        }
      );
    },
  },
  watch: {
    parentID() {
      this.Initialize();
    },
  },

  created() {
    this.filter = { IsPrimary: 0, FagID: this.parentID,  PrdStatusSap: 0 };
  },
};
</script>
